import { observer } from "mobx-react-lite";
import { lazy, Suspense } from "react";

import { Overlay } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { QRCodeDialog } from "@modules/forms/components/QRCodeDialog.tsx";
import { SendFormConfirmationDialog } from "@modules/forms/components/SendFormConfirmationDialog.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { When } from "@ui-components/withPerm.tsx";

const AppointmentDialog = lazy(
  () =>
    import(
      "@modules/booking/screens/booking-calendar/components/appointment-dialog/AppointmentDialog.tsx"
    )
);

const PatientDemographicDialog = lazy(
  () =>
    import(
      "@modules/practice/screens/contact-details/PatientDemographicDialog.tsx"
    )
);

const CurrentInvoiceDetailsModal = lazy(
  () =>
    import(
      "@modules/billing/screens/invoice/components/invoice-details-modal/CurrentInvoiceDetailsModal.tsx"
    )
);

const ManageTemplateDialog = lazy(
  () =>
    import(
      "@modules/clinical/screens/template-writer/components/ManageTemplateDialog.tsx"
    )
);

const CorrespondenceDialog = lazy(
  () =>
    import("@modules/clinical/screens/correspondence/CorrespondenceDialog.tsx")
);

const FormDesignListModalWrapper = lazy(
  () => import("@modules/form-design/screens/FormDesignListModalWrapper.tsx")
);

const TemplateManagementModalWrapper = lazy(
  () =>
    import("@modules/template-management/screens/TemplateListModalWrapper.tsx")
);

const StatementModalWrapper = lazy(
  () =>
    import(
      "@modules/billing/screens/account/components/statement-modal/StatementModalWrapper.tsx"
    )
);

const PatientMergeDialog = lazy(
  () =>
    import(
      "@modules/practice/screens/address-book/components/patient-merge/PatientMergeDialog.tsx"
    )
);

const BillingDocumentViewerDialog = lazy(
  () =>
    import(
      "@modules/billing/screens/shared-components/BillingDocumentViewer.tsx"
    )
);

const HelpDialog = lazy(
  () =>
    import(
      /* webpackChunkName: "ux.help.dialog"*/ "@modules/user-experience/components/HelpDialog.tsx"
    )
);

export const Dialogs = observer(() => {
  const {
    billing: {
      ui: { currentInvoiceDetails }
    },
    document: { showTemplateManagementModal },
    correspondence: {
      ui: { showTemplatePicker }
    },
    booking,
    practice: {
      ui: { showPatientMergeModal }
    },
    userExperience: {
      ui: { showHelpDialog }
    }
  } = useStores();

  const {
    ui: { formQRCodeUrl, hideFormQRCodeUrl }
  } = booking;
  return (
    <Suspense fallback={<Overlay styles={{ root: { zIndex: 100 } }} />}>
      <When
        permission={[
          Permission.ContactRead,
          Permission.ContactWrite,
          Permission.PatientRead,
          Permission.PatientWrite
        ]}
      >
        <PatientDemographicDialog />
      </When>
      <When
        permission={[
          Permission.AppointmentTypeRead,
          Permission.BookingScheduleRead,
          Permission.CalendarEventRead,
          Permission.CalendarEventWrite
        ]}
      >
        <AppointmentDialog />
        {formQRCodeUrl && (
          <QRCodeDialog
            hostingUrl={formQRCodeUrl}
            onClose={() => hideFormQRCodeUrl()}
          />
        )}
      </When>
      <When
        permission={[
          Permission.PracDocWriterAllowed,
          Permission.DocumentTemplateRead
        ]}
      >
        {showTemplatePicker && <CorrespondenceDialog />}
      </When>

      <FormDesignListModalWrapper />

      <When
        permission={[
          Permission.TemplateManagementAllowed,
          Permission.TemplateManagementRead
        ]}
      >
        <TemplateManagementModalWrapper />
      </When>

      <When permission={[Permission.DocumentTemplateWrite]}>
        {showTemplateManagementModal && <ManageTemplateDialog />}
      </When>
      <When permission={[Permission.InvoiceCreate]}>
        {!!currentInvoiceDetails && <CurrentInvoiceDetailsModal />}
      </When>
      <When permission={[Permission.StatementWrite]}>
        <StatementModalWrapper />
      </When>
      <SendFormConfirmationDialog />
      <BillingDocumentViewerDialog />
      <When
        permission={[
          Permission.PatientMergeAllowed,
          Permission.PatientMergeJobWrite
        ]}
      >
        {showPatientMergeModal && <PatientMergeDialog />}
      </When>
      {showHelpDialog && <HelpDialog />}
    </Suspense>
  );
});
