import React from "react";

import { Spinner, Stack, Suggestions, Text, useTheme } from "@bps/fluent-ui";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { getPatientSearchBoxStyles } from "./PatientAppointmentsSearch.styles.ts";

interface RecentSearchesListProps {
  onDismissCallout: () => void;
  searchPatientName: string;
  patientIds: string[];
}
export const RecentSearchesList: React.FC<RecentSearchesListProps> = ({
  onDismissCallout,
  searchPatientName,
  patientIds
}) => {
  const { practice } = useStores();
  const { showContactDetails } = practice.ui;

  const theme = useTheme();
  const { recentSearchTitleContainerStyles, recentSearchTextStyles } =
    getPatientSearchBoxStyles(theme);

  const fetchRecentSearchedPatient = async () => {
    if (searchPatientName.length < 2) {
      return await practice.getContactsById(patientIds);
    } else {
      return [];
    }
  };

  return (
    <>
      <Stack styles={recentSearchTitleContainerStyles}>
        <Text styles={recentSearchTextStyles}>Recent searches</Text>
      </Stack>
      {patientIds.length > 0 && (
        <DataFetcher<Contact[]>
          fetch={fetchRecentSearchedPatient}
          fallback={<Spinner styles={{ root: { padding: "16px 0px" } }} />}
        >
          {patients => {
            return (
              <Suggestions
                onSuggestionClick={(_ev, item) => {
                  showContactDetails(item.id);
                  onDismissCallout();
                }}
                onRenderSuggestion={(item: Contact) => (
                  <Text nowrap styles={{ root: { padding: "6px 12px" } }}>
                    {item?.name}
                  </Text>
                )}
                suggestions={patients.map((s: Contact) => ({
                  item: s,
                  selected: false
                }))}
              />
            );
          }}
        </DataFetcher>
      )}
    </>
  );
};
