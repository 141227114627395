import { stringify } from "query-string";

import { Country } from "@libs/enums/country.enum.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ApplyToDateKeys } from "@modules/billing/screens/acc-invoices/components/AccInvoiceTransactionsListFilter.types.ts";
import { useDraftRoute } from "@modules/billing/screens/draft-items/hooks/useDraftRoute.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { BillingStore } from "@stores/billing/BillingStore.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Nav } from "@ui-components/navigation/Nav.tsx";
import { When } from "@ui-components/withPerm.tsx";

export const newInvoiceNavLink = (billing: BillingStore) => ({
  name: "New invoice",
  permissions: [Permission.InvoiceCreate],
  onClick: () => billing.ui.showInvoiceDetailsDialog()
});

export const newPaymentNavLink = {
  name: "New payment",
  path: routes.accounts.allocations.new,
  showBorder: true
};

export const getFinanceNavLinks = (
  { routing, billing }: IRootStore,
  handleDraftClicked: () => void
) => [
  newInvoiceNavLink(billing),
  newPaymentNavLink,
  {
    name: "Find an ACC claim",
    path: routes.claims.basePath,
    tenantLocation: Country.NewZealand,
    showBorder: true
  },
  {
    name: "General accounts",
    path: routes.accounts.basePath
  },
  {
    name: "ACC billing",
    path: routes.accounts.accInvoices.basePath,
    tenantLocation: Country.NewZealand
  },
  {
    name: "Accredited billing",
    path: routes.accounts.accreditedBilling.basePath,
    onClick: () =>
      routing.push({
        pathname: routes.accounts.accreditedBilling.basePath.pattern,
        search: stringify({ applyToDate: ApplyToDateKeys.transactionDate })
      }),
    tenantLocation: Country.NewZealand
  },
  {
    name: "Draft items",
    path: routes.accounts.draftItems.basePath,
    onClick: handleDraftClicked,
    tenantLocation: Country.NewZealand
  }
];

export const FinanceNavButton = () => {
  const store = useStores();
  const goToDrafts = useDraftRoute();

  return (
    <When permission={Permission.AccountHistoryAllowed}>
      <Nav
        renderAsButton
        buttonProps={{ text: "Finance" }}
        links={getFinanceNavLinks(store, goToDrafts)}
      />
    </When>
  );
};
